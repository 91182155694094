import React from 'react';
import styled from 'react-emotion';
import Footer from '../components/footer';
import Navigation from '../components/navigation';
import Layout from '../layouts';

const Main = styled('div')({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '@media only screen and (max-width: 700px)': {
    justifyContent: 'flex-start',
  },
})

const Wrapper = styled('div')({
  margin: '0 auto',
  marginTop: '40px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  maxWidth: '700px',
  minHeight: '400px',
  marginBottom: '100px',
  '@media only screen and (max-width: 700px)': {
    margin: '0 20px auto 20px',
  },
})

const Heading = styled('h1')({
  fontSize: '42px',
  lineHeight: '40px',
  color: '#000000',
  fontWeight: '800',
  marginBottom: '40px',
  marginTop: '10px',
  '@media only screen and (max-width: 700px)': {
    fontSize: '20px',
    lineHeight: '30px',
    marginBottom: '20px',
  },
})

const Text = styled('div')({
  fontSize: '22px',
  color: '#808080',
  lineHeight: '30px',
  marginBottom: '40px',
  textAlign: 'left',
  '@media only screen and (max-width: 700px)': {
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '20px',
  },
})

const LargeText = styled('div')({
  fontSize: '32px',
  color: '#000000',
  lineHeight: '42px',
  marginBottom: '40px',
  textAlign: 'left',
  '@media only screen and (max-width: 700px)': {
    fontSize: '22px',
    lineHeight: '26px',
    marginBottom: '20px',
  },
})


const SecondPage = () => (
  <Layout>
    <Main>
      <Navigation />
      <Wrapper>
        <Heading>We respect your privacy</Heading>
        <LargeText>
          Privacy matters to us. We want to know what data is being collected
          and that it’s not going to be misused on the products that we
          personally use, and therefore hold Blocks up to the same expectation.
        </LargeText>
        <Text>
          We only ask for personal information when we truly need it to provide
          a service to you. We collect it by fair and lawful means, with your
          knowledge and consent. We also let you know why we’re collecting it
          and how it will be used.
        </Text>
        <Text>
          We only retain collected information for as long as necessary to
          provide you with your requested service. What data we store, we’ll
          protect within commercially acceptable means to prevent loss and
          theft, as well as unauthorised access, disclosure, copying, use or
          modification.
        </Text>
        <Text>
          We don’t share any personally identifying information publicly or with
          third-parties, except when required to by law.
        </Text>
        <Text>
          Our website may link to external sites that are not operated by us.
          Please be aware that we have no control over the content and practices
          of these sites, and cannot accept responsibility or liability for
          their respective privacy policies.
        </Text>
        <Text>
          You are free to refuse our request for your personal information, with
          the understanding that we may be unable to provide you with some of
          your desired services.
        </Text>
        <Text>
          Your continued use of our website will be regarded as acceptance of
          our practices around privacy and personal information. If you have any
          questions about how we handle user data and personal information, feel
          free to contact us.
        </Text>
        <Text>This policy is effective as of 1 July 2018.</Text>
      </Wrapper>
      <Footer />
    </Main>
  </Layout>
)

export default SecondPage
